@use "../../styles/constants";
@use "../../custom-bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.banner {
  margin-top: calc(-1rem - #{constants.$header-height});
  background-color: #000000;
  //background-color: rgb(245, 202, 74);
  //background-image: -webkit-linear-gradient(top,
  //        rgba(255,255,255),
  //        rgba(255,255,255,0),
  //        rgba(255,255,255,0),
  //        rgba(255,255,255)
  //), -webkit-linear-gradient(left,
  //                rgba(255,255,255),
  //                rgba(255,255,255,0),
  //                rgba(255,255,255,0),
  //                rgba(255,255,255,0),
  //                rgba(255,255,255)
  //), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23d19d00' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  //height: 800px;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;


  //p {
  //  color: white;
  //  //font-size: 3rem;
  //  font-weight: bold;
  //  //background-color: rgb(0, 0, 0, 0.5);
  //  padding: 1rem 2rem;
  //  border-radius: 1rem;
  //}

  //button {
  //  --bs-btn-padding-y: 0.5rem;
  //  --bs-btn-padding-x: 1rem;
  //  --bs-btn-font-size: 1.25rem;
  //  --bs-btn-font-weight: 600;
  //  --bs-btn-border-radius: var(--bs-border-radius-lg);
  //  //background-color: red;
  //  //border: red;
  //}

  img {
    width: 13rem;
    margin-bottom: 0.25rem;
    color: white;
  }
}

.hero-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.video {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 0.65;
}

.inner {
  background: rgba(0, 0, 0, 0);
  color: white;
  z-index: 1;
}

@include media-breakpoint-down(xxl) {
  .banner {
    //height: 600px;
  }
}


@include media-breakpoint-down(xl) {
  .banner {
    //height: 500px;

    //p {
    //  font-size: 2.5rem;
    //}

    img {
      width: 11rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .banner {
    //height: 400px;

    //p {
    //  //padding: 0.3rem 0.5rem;
    //  font-size: 2rem;
    //}

    img {
      width: 10.5rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .banner {
    //height: 370px;
    background-position: center top;

    //p {
    //  font-size: 1.8rem;
    //}

    //button {
    //  --bs-btn-padding-x: 0.75rem;
    //  --bs-btn-padding-y: 0.375rem;
    //  --bs-btn-font-size: 1rem;
    //  --bs-btn-border-radius: var(--bs-border-radius);
    //}

    img {
      width: 8.5rem;
    }
  }

  .credits {
    bottom: 5px;
    right: -10px;
    text-shadow: 0 0 10px #000, 0 0 10px #000, 0 0 10px #000, 0 0 10px #000, 0 0 10px #000;
  }
}

@include media-breakpoint-down(sm) {
  .banner {
    //p {
    //  font-size: 1.6rem;
    //}

    //a {
    //  --bs-btn-padding-y: 0.25rem;
    //  --bs-btn-padding-x: 0.5rem;
    //  --bs-btn-font-size: 0.875rem;
    //  --bs-btn-border-radius: var(--bs-border-radius-sm);
    //}

    img {
      width: 7rem;
    }

  }

  .explainer-card {
    p {
      font-size: 1rem;
    }
  }

  .usage-step-card {
    p {
      font-size: 1rem;
    }
  }
}

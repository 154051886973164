.notification {
    cursor: pointer;

    &:hover {
        background-color: #f2f2f2;
    }
}

.not_seen {
    background-color: rgba(var(--bs-primary-rgb), 0.1);

    &:hover {
        background-color: rgba(var(--bs-primary-rgb), 0.25);
    }
}

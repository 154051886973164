.modal {
  height: initial;
  animation: slide-down 300ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
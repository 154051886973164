@use "../../styles/constants";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.content {
  min-height: calc(100vh - #{constants.$header-height} - #{constants.$footer-sm-height});
}

@include media-breakpoint-up(sm) {
  .content {
    min-height: calc(100vh - #{constants.$header-height} - #{constants.$footer-height});
  }
}
@use "../../styles/constants";
@use "../../custom-bootstrap";

.header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: constants.$header-height;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    //border-bottom: 1px solid #e5e3dd;
}

.header-welcome-bg {
    background-color: rgb(0, 0, 0, 0.5);
}

.header-scroll-bg-1rem {
    background-color: #fafafb;
}

.header-scroll-bg-2rem {
    background-color: #fbfcfc;
}

.header-scroll-bg-3rem {
    background-color: #fdfdfe;
}

.header-scroll-bg {
    background-color: white;
}

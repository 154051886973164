@use "../../styles/constants";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.page-container {
    margin-top: constants.$header-height;
}

.page-content {
    > div {
        padding-bottom: constants.$footer-height;
    }
}

@include media-breakpoint-up(md) {
    .page-content {
        position: absolute;
        height: calc(100vh - #{constants.$header-height});
        width: calc(100% - #{constants.$navbar-width} - 1.5rem);
        right: 0;

        > div {
            padding-bottom: 2rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .page-content {
        width: calc(100% - #{constants.$navbar-lg-width} - 1.5rem);
    }
}

// Override default variables before the import
//$body-bg: #f6f7fa;
//$body-bg: #f2f3f6;
//$primary: #eba937;
//$primary: #b68b09;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

:root {
    //--bs-border-radius: 0rem;
    //--bs-border-radius-sm: 0rem;
    //--bs-border-radius-lg: 0rem;
    //--bs-border-radius-xl: 0rem;
    //--bs-border-radius-xxl: 0rem;
}

body {
    //background-color: #f2f3f6;
    font-family: 'Montserrat', sans-serif;
}

//.btn-primary {
//    --bs-btn-color: #fff;
//    --bs-btn-hover-color: #fff;
//    --bs-btn-active-color: #fff;
//    --bs-btn-disabled-color: #f0f0f0;
//    --bs-btn-hover-bg: #926f07;
//    --bs-btn-hover-border-color: #926f07;
//}

.nav-underline .nav-link {
    border-bottom: 0.0625rem solid transparent;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.nav-underline .nav-link.active {
    font-weight: normal;
}

.spinner-border {
    border-width: 0.0625rem;
}

.badge {
    line-height: initial;
}

.dropdown-menu {
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color: var(--bs-body-color);
    --bs-dropdown-link-active-bg: var(--bs-tertiary-bg);
}
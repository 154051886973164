@use "../../styles/constants";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.navbar {
    display: none;
    position: fixed;
    width: constants.$navbar-width;
    border-right: 1px solid #e5e3dd;
    height: calc(100vh - #{constants.$header-height});
}

.mobile-navbar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 1;
    background-color: white;
    padding: 0.5rem 1rem;

    a {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
}

@include media-breakpoint-up(lg) {
    .navbar {
        width: constants.$navbar-lg-width;
    }
    .mobile-navbar {
        a {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }
}

@include media-breakpoint-up(md) {
    .navbar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mobile-navbar {
        display: none;
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 0.55;
}

.carousel-caption {
  .carousel-caption-title {
    font-weight: bold;
    height: 60px;
    font-size: 2.2em;
  }

  .carousel-caption-subtitle {
    font-size: 2em;
    height: 80px;
    align-content: center;
  }

  .carousel-caption-link {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: var(--bs-border-radius-lg);
  }
}

@include media-breakpoint-down(xxl) {
  .carousel-caption {
    .carousel-caption-title {
      font-size: 2em;
    }

    .carousel-caption-subtitle {
      font-size: 1.8em;
    }
  }
}

@include media-breakpoint-down(xl) {
  .carousel-caption {
    .carousel-caption-title {
      font-size: 1.8em;
    }

    .carousel-caption-subtitle {
      font-size: 1.6em;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel-caption {
    .carousel-caption-title {
      font-size: 1.6em;
      height: 50px;
    }

    .carousel-caption-subtitle {
      font-size: 1.4em;
      height: 70px;
    }

    .carousel-caption-link {
      --bs-btn-padding-x: 0.75rem;
      --bs-btn-padding-y: 0.375rem;
      --bs-btn-font-size: 1rem;
      --bs-btn-border-radius: var(--bs-border-radius);
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel-caption {
    .carousel-caption-title {
      font-size: 1.4em;
    }

    .carousel-caption-subtitle {
      font-size: 1.2em;
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel-caption {
    .carousel-caption-title {
      font-size: 1.2em;
    }

    .carousel-caption-subtitle {
      font-size: 1em;
    }

    .carousel-caption-link {
      --bs-btn-padding-x: 0.5rem;
      --bs-btn-padding-y: 0.25rem;
      --bs-btn-font-size: 0.875rem;
      --bs-btn-border-radius: var(--bs-border-radius-sm);
    }
  }
}
